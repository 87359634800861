import React, { useState } from "react";
import Modal from "react-modal";
import "./SettingsModal.css";

Modal.setAppElement("#root");

const SettingsModal = ({
  isOpen,
  onRequestClose,
  onSave,
  currTimeInterval,
}) => {
  const [timeIntervalInSec, setTimeIntervalInSec] = useState(
    Math.round(currTimeInterval / 1000).toFixed(1),
  );
  const handleSave = () => {
    onSave(timeIntervalInSec * 1000);
    onRequestClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Settings"
    >
      <div className="modal-content">
        <h2>Settings</h2>
        <div>
          <label>
            Time Interval (second):
            <input
              type="number"
              value={timeIntervalInSec}
              onChange={(e) =>
                setTimeIntervalInSec(parseInt(e.target.value, 10))
              }
            />
          </label>
        </div>
        <div className="modal-buttons">
          <button onClick={handleSave}>Save</button>
          <button onClick={onRequestClose}>Cancel</button>
        </div>
      </div>
    </Modal>
  );
};

export default SettingsModal;

import React, { useRef, useEffect, useState } from "react";
import "./CourtBackground.css";
import SettingsModal from "./SettingsModal";

const CourtBackground = (props) => {
  const svgRef = useRef(null);
  // const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [activeRect, setActiveRect] = useState(null);
  const activeRectRef = useRef(activeRect);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [intervalTime, setIntervalTime] = useState(3000);
  const setIntervalRef = useRef(null);

  //   useEffect(() => {
  //     const updateDimensions = () => {
  //       if (svgRef.current) {
  //         const { width, height } = svgRef.current.getBoundingClientRect();
  //         setDimensions({ width, height });
  //       }
  //     };

  //     updateDimensions(); // Set dimensions on mount
  //     window.addEventListener('resize', updateDimensions); // Update dimensions on resize

  //     return () => window.removeEventListener('resize', updateDimensions); // Clean up
  //   }, []);

  useEffect(() => {
    if (setIntervalRef.current) {
      clearInterval(setIntervalRef.current);
    }
    const interval = setInterval(() => {
      let randomRect = activeRectRef.current;
      while (randomRect === activeRectRef.current) {
        randomRect = Math.floor(Math.random() * 6);
      }
      setActiveRect(randomRect);
      activeRectRef.current = randomRect;
    }, intervalTime);
    setIntervalRef.current = interval;

    return () => clearInterval(setIntervalRef.current);
  }, [intervalTime]);

  const rects = [
    { x: 0, y: 0, width: 108.07, height: 80.186 },
    { x: 0, y: 80.186, width: 108.07, height: 80.186 },
    { x: 0, y: 160.373, width: 108.07, height: 80.186 },
    { x: 108.07, y: 0, width: 108.07, height: 80.186 },
    { x: 108.07, y: 80.186, width: 108.07, height: 80.186 },
    { x: 108.07, y: 160.373, width: 108.07, height: 80.186 },
  ];

  const handleDoubleClick = () => {
    setIsModalOpen(true);
  };

  const handleSave = (time) => {
    setIntervalTime(time);
  };

  useEffect(() => {
    const svgElement = svgRef.current;
    svgElement.addEventListener("dblclick", handleDoubleClick);

    return () => {
      svgElement.removeEventListener("dblclick", handleDoubleClick);
    };
  }, []);

  return (
    <>
      <SettingsModal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        onSave={handleSave}
        currTimeInterval={intervalTime}
      />
      <svg
        ref={svgRef}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 216.145 240.559"
        {...props}
      >
        <g transform="translate(-129.327 -941.135)">
          <path
            d="M941.135-345.472h240.559v216.142H941.135z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#349850",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10000115,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 1,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="rotate(90)"
          />
          <path
            d="M1180.277-345.472h1.417v216.145h-1.417zm-26.93 0h1.417v216.145h-1.417zm-138.898 0h1.417v216.145h-1.417z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10000115,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 1,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="rotate(90)"
          />
          <path
            d="M-130.744-1181.694h1.417v240.555h-1.417z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10083047,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 1,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="scale(-1)"
          />
          <path
            d="M-345.468-1181.694h1.417v240.555h-1.417z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.07743022,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 1,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="scale(-1)"
          />
          <path
            d="M-147.057-1181.694h1.417v240.555h-1.417z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10766187,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="scale(-1)"
          />
          <path
            d="M-330.587-1181.694h1.417v240.559h-1.417z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10000115,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 1,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="scale(-1)"
          />
          <path
            d="M-238.108-1181.694h1.618v166.502h-1.618z"
            style={{
              color: "#000",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              fill: "#fff",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.10629921,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              enableBackground: "accumulate",
            }}
            transform="scale(-1)"
          />
          <path
            d="M345.472 944.29H129.327"
            style={{
              fill: "none",
              fillRule: "evenodd",
              stroke: "#000",
              strokeWidth: 0.35433477,
              strokeLinecap: "butt",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeOpacity: 1,
            }}
          />
          <circle
            cx={130.035}
            cy={944.291}
            r={0.886}
            style={{
              color: "#000",
              clipRule: "nonzero",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              isolation: "auto",
              mixBlendMode: "normal",
              colorInterpolation: "sRGB",
              colorInterpolationFilters: "linearRGB",
              solidColor: "#000",
              solidOpacity: 1,
              fill: "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.04401815,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              colorRendering: "auto",
              imageRendering: "auto",
              shapeRendering: "auto",
              textRendering: "auto",
              enableBackground: "accumulate",
            }}
          />
          <circle
            cx={344.76}
            cy={944.291}
            r={0.886}
            style={{
              color: "#000",
              clipRule: "nonzero",
              display: "inline",
              overflow: "visible",
              visibility: "visible",
              opacity: 1,
              isolation: "auto",
              mixBlendMode: "normal",
              colorInterpolation: "sRGB",
              colorInterpolationFilters: "linearRGB",
              solidColor: "#000",
              solidOpacity: 1,
              fill: "#000",
              fillOpacity: 1,
              fillRule: "nonzero",
              stroke: "none",
              strokeWidth: 0.04401815,
              strokeLinecap: "round",
              strokeLinejoin: "miter",
              strokeMiterlimit: 4,
              strokeDasharray: "none",
              strokeDashoffset: 0,
              strokeOpacity: 1,
              marker: "none",
              colorRendering: "auto",
              imageRendering: "auto",
              shapeRendering: "auto",
              textRendering: "auto",
              enableBackground: "accumulate",
            }}
          />
        </g>
        {rects.map((rect, index) => {
          console.log(`activeRect: ${activeRect}`);
          console.log(
            `rect: ${index}, ${rect.x}, ${rect.y}, ${rect.width}, ${rect.height}`,
          );
          return (
            <rect
              key={index}
              x={rect.x}
              y={rect.y}
              width={rect.width}
              height={rect.height}
              className={`highlight-rect ${activeRect === index ? "active" : ""}`}
            />
          );
        })}
      </svg>
    </>
  );
};

export default CourtBackground;
